<template>
  <viewcard--c
    title="Detalhes da Pendência"
    :btsave="null"
    :btdelete="null"
    :btback="{}"
    :busy="loading"
  >
    <b-modal
      :id="'modal-new-subjects'"
      ref="modal-new-subjects"
      scrollable
      title="Incluir Gravações"
      size="lg"
      @ok="changeState(data)"
      ok-title="Salvar"
      ok-variant="info"
    >
      <b-row class="mb-1 d-flex justify-content-end">
        <b-col md="6">
          <b-input-group>
            <b-form-input
              placeholder="Pesquise por assunto, evento, aula..."
              autocomplete="off"
              v-model="search"
              @keyup.enter="searchSubjectRecord"
            />
            <b-input-group-append>
              <b-button variant="info" @click="searchSubjectRecord">
                <feather-icon icon="SearchIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>

      <b-row style="min-height: 200px; margin-top: 10px">
        <b-col cols="12" v-if="mediaSubjectSelected.length != 0">
          <app-timeline class="mt-1 ml-3">
            <app-timeline-item
              v-for="(element, id) in mediaSubjectSelected"
              :key="id"
              icon="FilmIcon"
              variant="secondary"
            >
              <div class="mb-1 mb-sm-0">
                <div class="d-flex justify-content-between">
                  <div>
                    <h6 class="m-0">
                      Gravação: {{ element.recording_media_id }} -
                      {{ element.subject }}
                    </h6>
                    <p style="line-height: 1.1">
                      <small v-if="element.recorded_at" class="m-0 text-muted">
                        Gravada em: {{ element.recorded_at }}
                      </small>
                      <br />
                      <small class="m-0 text-muted">
                        Disciplina:
                        {{ getDisciplineName(element) }}
                      </small>
                      <br />
                      <small class="m-0 text-muted">
                        Classificação:
                        {{ getClassificationPlatformName(element) }}
                      </small>
                      <br />
                      <small v-if="element.product_name" class="m-0 text-muted">
                        Produto: {{ element.product_name }}
                      </small>
                    </p>
                  </div>
                  <div>
                    <feather-icon
                      :id="`modal-new-subjects-${element.id}`"
                      icon="Trash2Icon"
                      class="text-danger mr-50"
                      @click="onClickRemove(element)"
                      size="20"
                      style="cursor: pointer"
                    />
                  </div>
                </div>
              </div>
            </app-timeline-item>
          </app-timeline>
        </b-col>
        <b-col v-else class="mt-1" style="text-align: center">
          Nenhum assunto encontrado
        </b-col>
      </b-row>
    </b-modal>
    <!-- INICIO MODAL VÍDEOS ARCHIVE -->
    <b-modal
      size="lg"
      ref="modal-videos-list"
      title="Vídeos"
      hide-footer
      scrollable
    >
      <b-row
        style="min-height: 200px; margin-top: 10px"
        v-if="currentVideo != null"
      >
        <b-col cols="12">
          <div class="d-flex align-items-center justify-content-center">
            <video-player
              class="video-player-box"
              :ref="'video' + currentVideo.id"
              :options="currentVideo.playOption"
              customEventName="customstatechangedeventname"
              style="cursor: pointer; text-decoration: underline"
            >
            </video-player>
            <video
              :src="currentVideo.url_media"
              :id="`video-${currentVideo.id}`"
              class="d-none"
              height="240"
              width="320"
              controls
            />
          </div>
        </b-col>
        <b-col cols="12">
          <div class="d-flex align-items-center justify-content-center">
            <span style="text-align: center"
              ><strong>{{ currentVideo.original_filename }}</strong></span
            >
          </div>
        </b-col>
        <b-col cols="12">
          <hr />
        </b-col>
      </b-row>
      <b-row style="margin-bottom: 8px; margin-top: 20px">
        <b-col style="text-align: right">
          <b-button @click="onClickModalVideoList(false)">Fechar</b-button>
        </b-col>
      </b-row>
    </b-modal>
    <!-- FIM MODAL VÍDEOS ARCHIVE -->

    <b-modal
      ref="modal-search-subject"
      scrollable
      title="Pesquisa de Assuntos das Gravações"
      size="xl"
      hide-footer
    >
      <b-overlay :variant="skin" :show="loading" rounded="sm">
        <Table
          :fields="fields"
          :list="!loading && list[currentePage] ? list[currentePage].itens : []"
          @orderBy="getSubjectsRecordOrderBy"
          style="font-size: 9pt"
          border="simple"
          responsive
          hover
        >
          <template #media_subject_id="data">
            <div v-if="data.item.media_archive != null">
              <b-badge
                :id="`video-archive-${data.item.media_archive.id}`"
                class="mr-1"
                variant="info"
                @click="onClickModalVideoList(true, data.item.media_archive)"
              >
                <feather-icon icon="ArchiveIcon" />
              </b-badge>
              <b-link @click="onClickSelected(data.item)">
                #{{ data.item.media_subject_id }}
              </b-link>
            </div>
            <div v-else>
              <b-link @click="onClickSelected(data.item)">
                #{{ data.item.media_subject_id }}
              </b-link>
            </div>
          </template>
          <template #subject="data">
            <div
              :data-text="data.item.subject ? data.item.subject : '--'"
              class="text-truncate ellipsis"
              style="max-width: 250px"
            >
              {{ data.item.subject ? data.item.subject : "--" }}
            </div>
          </template>
        </Table>

        <b-pagination
          v-model="currentePage"
          @change="getLoadMore"
          :total-rows="rows"
          :per-page="size"
          v-if="rows > 1"
          first-number
          last-number
          align="center"
          prev-class="prev-item"
          next-class="next-item"
          responsive
          class="mt-1"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-overlay>
    </b-modal>

    <hr class="p-0 m-0 mb-1" />

    <b-tabs v-if="!loading" pills vertical>
      <b-tab title="Informações" active>
        <b-row>
          <b-col>
            <span class="card-text text-nowrap">
              Código da Pendência: <strong>{{ data.id }}</strong>
            </span>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <span class="card-text text-nowrap">
              Data Limite:
              <strong>{{
                data.limitDate.split("T")[0].split("-").reverse().join("/")
              }}</strong>
            </span>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <span class="card-text text-nowrap">
              Empresa: <strong>{{ data.company_name }}</strong>
            </span>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <span class="card-text text-nowrap">
              Professor:<strong> {{ data.teacher_name }}</strong>
            </span>
          </b-col>
        </b-row>
        <b-row v-if="data.project_name">
          <b-col>
            <span class="card-text text-nowrap">
              Projeto associado:<strong> {{ data.project_name }}</strong>
            </span>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <span class="card-text text-nowrap">
              Estado da Pendência:
              <b-badge v-if="data.completed" variant="success">
                Concluída
              </b-badge>
              <b-badge v-else variant="warning"> Pendente </b-badge>
            </span>
          </b-col>
        </b-row>
        <br />
        <b-row>
          <b-col>
            <span class="card-text text-nowrap">
              Data de Criação:
              <strong>{{
                data.created_at.split("T")[0].split("-").reverse().join("/")
              }}</strong>
            </span>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <span class="card-text text-nowrap">
              Data da Ultima Modificação:
              <strong>{{
                data.updated_at.split("T")[0].split("-").reverse().join("/")
              }}</strong>
            </span>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Assuntos">
        <div v-for="(rmsp, id) of data.recordingMediaSubjectPendings" :key="id">
          <b-col v-if="!data.completed">
            <div>
              <b-button
                class="float-right"
                @click="listMediaSubjectsPendings(rmsp, data.teacher_id)"
                variant="info"
              >
                +/- Gravações
              </b-button>
            </div>
          </b-col>
          <h5>{{ rmsp.subject }}</h5>
          <span
            >Criado em:
            {{ rmsp.created_at.split("T")[0].split("-").reverse().join("/") }}
          </span>
          <app-timeline
            v-if="
              rmsp.mediaSubjectPendings && rmsp.mediaSubjectPendings.length > 0
            "
            class="mt-1 ml-3"
          >
            <template v-for="(msub, index) in rmsp.mediaSubjectPendings">
              <app-timeline-item
                v-if="msub.media_subject != null"
                :key="index"
                icon="FilmIcon"
                variant="secondary"
              >
                <div class="mb-1 mb-sm-0">
                  <h6 class="m-0">
                    <b-link
                      class="underline"
                      :to="{
                        name: 'audiovisual-recording-view',
                        params: {
                          id: `${msub.recording_media_id}`,
                        },
                      }"
                    >
                      Gravação:
                      {{ msub.recording_media_id }}
                      -
                    </b-link>
                    {{ msub.media_subject }}
                  </h6>

                  <p style="line-height: 1.1">
                    <small v-if="msub.recorded_at" class="m-0 text-muted">
                      Gravada em:
                      {{
                        msub.recorded_at
                          .split("T")[0]
                          .split("-")
                          .reverse()
                          .join("/")
                      }}
                    </small>
                    <br />
                    <small class="m-0 text-muted"
                      >Associada em:
                      {{
                        msub.created_at
                          .split("T")[0]
                          .split("-")
                          .reverse()
                          .join("/")
                      }}</small
                    >
                    <br />
                    <small class="m-0 text-muted">
                      Disciplina: {{ getDisciplineName(msub) }}
                    </small>
                    <br />
                    <small class="m-0 text-muted">
                      Classificação: {{ getClassificationPlatformName(msub) }}
                    </small>
                    <br />
                    <small v-if="msub.product_name" class="m-0 text-muted">
                      Produto: {{ msub.product_name }}
                    </small>
                  </p>
                </div>
              </app-timeline-item>
            </template>
          </app-timeline>
        </div>
      </b-tab>
    </b-tabs>
  </viewcard--c>
</template>

<script>
import useAppConfig from "@core/app-config/useAppConfig";
import "video.js/dist/video-js.css";
import { videoPlayer } from "vue-video-player";
import Table from "@/components/Table.vue";
import { BTab, BTabs, BLink, BPagination, BOverlay } from "bootstrap-vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import _recordingPendingService from "@/services/audiovisual/recording-pending";
import _mediaSubjectVideoService from "@/services/audiovisual/media-subject-video-service";
import _mediaSubjectPendingService from "@/services/audiovisual/media-subject-pending-service";
import _recordingMediaPendingSubjectService from "@/services/audiovisual/recording-media-pending-subject-service";
export default {
  setup() {
    const { skin } = useAppConfig();
    return {
      skin,
    };
  },
  components: {
    BTab,
    BTabs,
    BLink,
    AppTimeline,
    AppTimelineItem,
    Table,
    BPagination,
    BOverlay,
    videoPlayer,
  },
  data() {
    return {
      loading: false,
      data: null,
      data_load: null,
      currentePage: 1,
      search: null,
      size: this.$utils.paginationSize(),
      rows: 1,
      fields: [
        { key: "media_subject_id", label: "ID Assunto" },
        { key: "recording_media_id", label: "ID Gravação" },
        { key: "discipline_name", label: "Disciplina" },
        { key: "subject", label: "Assunto" },
        { key: "recorded_at", label: "Gravado em" },
        { key: "product_name", label: "Plataforma" },
      ],
      list: [
        {
          page: 0,
          itens: [],
        },
      ],
      orderByParams: {
        search: "",
        ascOrDes: true,
        orderByKey: "",
      },
      mediaSubjectSelected: [],
      rmsp_edit: [],
      currentVideo: null,
      dynamicWidth: 500,
    };
  },
  created() {
    this.getRecord();
  },
  methods: {
    async getRecord() {
      this.loading = true;
      try {
        let data_load = (
          await _recordingPendingService.findByIdDetails(this.$route.params.id)
        ).content;

        if (data_load.id > 0) {
          data_load.recordingMediaSubjectPendings = (
            await _recordingMediaPendingSubjectService.filter(data_load.id)
          ).content;

          if (
            data_load.recordingMediaSubjectPendings != null &&
            data_load.recordingMediaSubjectPendings.length > 0
          ) {
            let count = 0;
            for (let rmsp of data_load.recordingMediaSubjectPendings) {
              data_load.recordingMediaSubjectPendings[
                count
              ].mediaSubjectPendings = (
                await _mediaSubjectPendingService.filter(rmsp.id)
              ).content;
              count++;
            }
          }
          this.data = data_load;
          this.loading = false;
        }
      } catch (error) {
        this.$utils.toastError("Notificação", error.toString());
      }
    },
    listMediaSubjectsPendings(rmsp, _teacher_id) {
      this.mediaSubjectSelected = [];
      this.rmsp_edit = [];
      let subjects = [];

      subjects = rmsp.mediaSubjectPendings
        .filter((f) => f.media_subject != null)
        .map((m) => {
          return {
            id: m.id,
            recording_media_subject_pending_id:
              m.recording_media_subject_pending_id,
            media_subject_id: m.media_subject_id,
            subject: m.media_subject,
            recording_media_id: m.recording_media_id,
            discipline_name: m.discipline_name,
            classification_name: m.classification_name,
            product_name: m.product_name,
            recorded_at: m.recorded_at
              .split("T")[0]
              .split("-")
              .reverse()
              .join("/"),
          };
        });

      this.rmsp_edit.push({
        recording_media_subject_pending_id: rmsp.id,
        teacher_id: _teacher_id,
        subjects: subjects,
      });

      this.mediaSubjectSelected = subjects;

      this.$refs["modal-new-subjects"].show();
    }, //ok
    searchSubjectRecord() {
      this.$refs["modal-search-subject"].show();
      this.currentePage = 1;
      this.rows = 1;
      this.list = [{ page: 0, itens: [] }];
      this.getSubjectsRecord(this.currentePage);
    }, //ok
    getSubjectsRecord(_page, isOrder = false) {
      this.loading = true;

      let _params = {
        search: this.search,
        teacher_id: this.data.teacher_id,
        is_future_transmission: this.data.appointment_id != null ? true : false,
      };

      _mediaSubjectVideoService
        .searchByTeacher(_page, _params)
        .then((res) => {
          if (res.content) {
            let searchTable = [];

            res.content.forEach((fe) => {
              searchTable.push({
                id: null,
                media_subject_id: fe.id,
                subject: fe.subject,
                recording_media_id: fe.recording_media_id,
                recorded_at: fe.recorded_at
                  .split("T")[0]
                  .split("-")
                  .reverse()
                  .join("/"),
                product_name: fe.product_name,
                discipline_name: fe.discipline_name,
                media_archive: fe.media_archive,
              });
            });

            //Não listar assuntos já selecionados
            if (this.mediaSubjectSelected.length > 0) {
              searchTable = searchTable.filter(
                (i) =>
                  !this.mediaSubjectSelected
                    .map((m) => m.media_subject_id)
                    .includes(i.media_subject_id)
              );
            }

            this.list.push({ page: _page, itens: searchTable });

            if (res.content.length > 0) {
              if (isOrder) return;
              this.rows += res.content.length;
              this.currentePage = _page;
            }
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => {
          this.orderList();
          this.loading = false;
        });
    },

    getSubjectsRecordOrderBy(_params) {
      this.orderByParams.ascOrDes = _params.ascOrDes;
      this.orderByParams.orderByKey = _params.orderByKey;
      let oldList = this.list.map((m) => m);
      this.list = [{ page: 0, itens: [] }];
      oldList.forEach((l) => {
        if (l.page < 1) return;
        this.getSubjectsRecord(l.page, _params, true);
      });
    },
    showOrderBy(_params) {
      this.orderByParams.ascOrDes = _params.ascOrDes;
      this.orderByParams.orderByKey = _params.orderByKey;
      let oldList = this.list.map((m) => m);
      this.list = [{ page: 0, itens: [] }];
      oldList.forEach((l) => {
        if (l.page < 1) return;
        this.getSubjectsRecord(l.page, true);
      });
    },
    orderList() {
      this.list.sort((a, b) => {
        if (a.page < b.page) return -1;
        if (a.page > b.page) return 1;
        return 0;
      });
    },
    getLoadMore(_page) {
      if (!this.list.some((s) => s.page === _page)) {
        this.getSubjectsRecord(_page);
      }
    },
    onClickSelected(record, _) {
      this.mediaSubjectSelected.push(record);
      this.$refs["modal-search-subject"].hide();
    },
    onClickRemove(item) {
      this.mediaSubjectSelected = this.mediaSubjectSelected.filter(
        (f) => f.id !== item.id
      );
    },
    async applySubjects(data) {
      //monta payload
      let payload_add = { records: [] };
      let payload_remove = { records: [] };

      // remover
      payload_remove.records = data.recordingMediaSubjectPendings
        .filter(
          (f) => f.id == this.rmsp_edit[0].recording_media_subject_pending_id
        )[0]
        .mediaSubjectPendings.filter(
          (i) =>
            !this.mediaSubjectSelected
              .map((m) => m.media_subject_id)
              .includes(i.media_subject_id)
        )
        .map((m) => {
          return { id: m.id };
        });

      // adicionar
      this.mediaSubjectSelected.forEach((fe) => {
        if (fe.id == null)
          payload_add.records.push({
            recording_media_subject_pending_id:
              this.rmsp_edit[0].recording_media_subject_pending_id,
            media_subject_id: fe.media_subject_id,
          });
      });

      if (payload_add.records.length > 0) {
        try {
          await _mediaSubjectPendingService.create(payload_add);
          this.$utils.toast(
            "Notificação",
            "Assunto(s) adicionado(s) com sucesso."
          );
        } catch (error) {
          this.$utils.toastError("Notificação", error.toString());
        }
      }

      if (payload_remove.records.length > 0) {
        for (const fe of payload_remove.records) {
          try {
            await _mediaSubjectPendingService.delete(fe.id);
            this.$utils.toast(
              "Notificação",
              "Assunto(s) removido(s) com sucesso."
            );
          } catch (error) {
            this.$utils.toastError("Notificação", error.toString());
          }
        }
      }

      this.getRecord();
    },
    changeState(data) {
      // encerrar pendência?
      this.$swal({
        title: "Concluir a pendência?",
        text: "Deseja concluir a pendência ou somente alterá-la?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Concluir a pendência!",
        cancelButtonText: "Alterar somente.",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          const payload = {
            data: {
              completed: true,
              id: data.id,
            },
          };

          this.loading = true;
          _recordingPendingService
            .changeCompleted(payload)
            .then(() => {
              this.data.completed = true;
              this.$utils.toast(
                "Notificação",
                "Pendência concluida com sucesso."
              );
            })
            .catch((error) => this.$utils.toastError("Notificação", error));
        }
        //conclui alterações da pendência
        this.applySubjects(data);
      });
    },
    onClickModalVideoList(action, _payload) {
      if (action) {
        this.currentVideo = _payload;
        if (_payload.url_media != null && _payload.url_media.length > 0) {
          let playerOptionsSub = {
            autoplay: false,
            muted: false,
            language: "en",
            width: this.dynamicWidth + "px",
            height: "320px",
            playbackRates: [0.7, 1.0, 1.5, 2.0],
            bigPlayButton: false,
            sources: [
              {
                type: "video/mp4",
                src: _payload.url_media,
              },
            ],
          };
          _payload.playOption = playerOptionsSub;
        }

        this.$refs["modal-videos-list"].show();
      } else {
        this.$refs["modal-videos-list"].hide();
      }
    },
    getDisciplineName(element) {
      if (element.discipline_name) return element.discipline_name;
      return "--";
    },
    getClassificationPlatformName(element) {
      if (element.classification_platform_name) return element.classification_platform_name;
      return "--";
    },
  },
};
</script>
<style scoped>
.ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.2s linear;
  max-width: 250px;
  padding: 0.5rem 0rem;
}
.ellipsis:focus,
.ellipsis:hover {
  color: transparent;
}
.ellipsis:focus:after,
.ellipsis:hover:after {
  content: attr(data-text);
  overflow: visible;
  text-overflow: inherit;
  background: #fff;
  position: absolute;
  left: auto;
  top: auto;
  width: auto;
  border: 1px solid #eaebec;
  padding: 0 0.5rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
  white-space: nowrap;
  word-wrap: break-word;
  display: block;
  color: black;
  margin-top: -1.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 500px;
}
</style>
